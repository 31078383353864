import Axios from 'axios'
import { BASE_URL } from './credentials'

const url = BASE_URL + 'users/create'
// const url = 'http://localhost:4000/api/v1/users/create'
export default async function APIcreateUser(user) {
    const newUser = {
        "name": user.name || "",
        "displayName": user.displayname || "",
        "email": user.email || "",
        "phoneNumber": user.phoneNumber || "",
        "photoUrl": user.photoUrl || "",
        "createdAt": user.createdAt,
        "lastLogin": user.lastLoginAt,
        "emailVerified": user.emailVerified,

    }
    await Axios.post(url, { "user": user }, {
        headers: { "Content-Type": 'application/x-www-form-urlencoded ' }
    })
    console.log("User Created.")

}