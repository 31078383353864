import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, } from "firebase/auth";
export const firebaseConfig = {
    apiKey: "AIzaSyDEs_jP0DJZhKZwglvGWuZxJxBbWsUyqA8",
    authDomain: "moss-1e6cf.firebaseapp.com",
    projectId: "moss-1e6cf",
    storageBucket: "moss-1e6cf.appspot.com",
    messagingSenderId: "1067089783629",
    appId: "1:1067089783629:web:fd8f74a2cfe30af8026504",
    measurementId: "G-4YHPRYE2WF"
};

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });



// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const signInWithGoogle = () => signInWithPopup(auth, provider);
export default app

