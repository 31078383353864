import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Home from '../pages/Home'
import About from '../pages/About'
import Testing from '../pages/Testing'
import Login from '../pages/Login'
import RatingSystem from '../RatingSystem/RatingSystem'

export default function Router() {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/Home' element={<Home />} />
            <Route path='/About' element={<About />} />
            <Route path='/Rating' element={<RatingSystem />} />
            <Route path='/Login' element={<Login />} />
        </Routes>
    )
}
