import { React, useEffect, useState, } from 'react'
import { Link, useNavigate, use } from 'react-router-dom'
import { COLORS } from '../../Constants/Colors'
import '../../index.css'
import { persistor, store } from '../../redux/store'
import { LOGOUT } from '../../redux/actions/actionTypes'

export default function Navbar() {
    const [user, setUser] = useState()
    useEffect(() => {
        setUser(store.getState().user)
    }, [])
    const navigate = useNavigate()
    return (
        < div >
            <ul className='navbar' style={{ backgroundColor: COLORS.background }}>
                <li>
                    <a href='/'>Home</a>
                </li>
                <li>
                    <a href='/about'>About</a>
                </li>
                {/* <li>
                    <a href='/test'>Test</a>
                </li> */}

                <li style={{ position: 'absolute', right: 0 }}>
                    {user ? <Link onClick={async () => { await persistor.purge(); store.dispatch({ type: LOGOUT }); setUser(null); navigate(0) }} >Logout</Link> : <Link to='/login'>Login</Link>}
                </li>
            </ul>
        </div >

    )
}
