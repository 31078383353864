import React from 'react'
import Card from '../Card/Card'
import { COLORS } from '../../Constants/Colors'
import { useNavigate } from 'react-router-dom'
import { store } from '../../redux/store'
import "../../index.css"


export default function Home() {
    const user = store.getState().user
    const navigate = useNavigate()
    return (
        <div className='home-page'>
            <div className='page-header'>
                <h1>Welcome to Moss.</h1>
            </div>
            <div className='home-page-body'>
                {user ?
                    <div className='home-page-icon-cards'>

                        <div className='iconcard' onClick={() => navigate('/Rating')}>
                            <h2>Rate your day.</h2>
                        </div>

                    </div>
                    :
                    <div>
                        Sign in to use all features
                    </div>}
            </div>

        </div >
    )
}
