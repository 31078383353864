import { useEffect } from 'react';
import Navbar from './Components/Navbar/Navbar';
import Router from './Components/Routes/Router';
import { store } from './redux/store';
import { LOGOUT } from './redux/actions/actionTypes';

function App() {

  return (
    <>
      <Navbar />
      <Router />
    </>
  );
}

export default App;
