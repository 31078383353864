import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../Firebase/config';
import { useNavigate } from 'react-router-dom'
import { signInWithGoogle } from '../../Firebase/config';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import APIcreateUser from '../../api/createUser';
import { LOGIN } from '../../redux/actions/actionTypes';
import { useDispatch } from 'react-redux';
import GoogleButton from 'react-google-button'
import "../../index.css"


const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [signUpEmail, setSignUpEmail] = useState('');
    const [signUppassword, setSignUpPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const dispatch = useDispatch()
    const onSubmitGoogleSignIn = async () => {
        const userCredential = await signInWithGoogle()
            .catch(er => { setErrorMessage("Failed to sign in with Google."); console.log(er) })
        dispatch({ type: LOGIN, payload: userCredential.user.toJSON() })
        navigate('/')
        navigate(0)
    }


    const onSignUp = async (e) => {
        e.preventDefault()

        await createUserWithEmailAndPassword(auth, signUpEmail, signUppassword)
            .then(async (userCredential) => {

                let user = userCredential.user.toJSON()
                await APIcreateUser(user)
                dispatch({ action: LOGIN, payload: user })
                navigate("/login")
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                // ..
            });


    }

    const onLogin = async (e) => {
        e.preventDefault();
        let userCredential
        try {
            userCredential = await signInWithEmailAndPassword(auth, email, password)
        } catch (error) {
            setErrorMessage(error.message)
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            return
        }
        try {
            const user = userCredential.user;
            dispatch({ type: LOGIN, payload: user.toJSON() })
            navigate('/')
            navigate(0)
        } catch (error) {
            console.log(error)
            return
        }


    }

    return (
        <>
            <main >

                <div style={{ textAlign: 'center' }}>
                    <div style={{ borderRadius: 25, backgroundColor: 'white', padding: 15, display: 'inline-block', margin: 15, width: '40%' }}>
                        <div>
                            <h1 style={{ marginBottom: 15 }}>Login</h1>
                            <form>
                                <div style={{ textAlign: 'left', paddingLeft: '20%' }}>
                                    <label htmlFor="email-address" >
                                        Email Address
                                    </label>
                                </div>
                                <div style={{ marginBottom: 15, marginTop: 10 }}>
                                    <input style={styles.inputTextBox}
                                        id="email-address"
                                        name="email"
                                        type="email"
                                        required
                                        placeholder="Email address"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div style={{ textAlign: 'left', paddingLeft: '20%' }}>
                                    <label htmlFor="password">
                                        Password
                                    </label>
                                </div>
                                <div style={{ marginTop: 10, marginBottom: 10 }}>
                                    <input
                                        style={styles.inputTextBox}
                                        id="password"
                                        name="password"
                                        type="password"
                                        required
                                        placeholder="Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>

                                <div>
                                    <button className='default-button' type='submit' onClick={onLogin}>
                                        Login
                                    </button>
                                </div>

                            </form>

                        </div>
                    </div>
                    <div style={{ borderRadius: 25, backgroundColor: 'white', padding: 15, display: 'inline-block', margin: 15, width: '40%' }}>
                        <h1 style={{ marginBottom: 15 }}>Sign Up</h1>
                        <form>
                            <div style={{ textAlign: 'left', paddingLeft: '20%' }}>

                                <label htmlFor="email-address-sign-up">
                                    Email address
                                </label>
                            </div>
                            <div style={{ marginBottom: 15, marginTop: 10 }}>
                                <input

                                    style={styles.inputTextBox}
                                    id="email-address-sign-up"
                                    name="email"
                                    type="email"
                                    required
                                    placeholder="Email address"
                                    onChange={(e) => setSignUpEmail(e.target.value)}
                                />
                            </div>
                            <div style={{ textAlign: 'left', paddingLeft: '20%' }}>

                                <label htmlFor="password-sign-up">
                                    Password
                                </label>
                            </div>
                            <div style={{ marginBottom: 10, marginTop: 10 }}>
                                <input
                                    style={styles.inputTextBox}

                                    id="password-sign-up"
                                    name="password-sign-up"
                                    type="password"
                                    required
                                    placeholder="Password"
                                    onChange={(e) => setSignUpPassword(e.target.value)}
                                />
                            </div>
                            <button className='default-button' type='submit' onClick={onSignUp}> Sign Up </button>
                        </form>

                    </div>
                </div>
                <div style={{ paddingLeft: '42%' }}>
                    <GoogleButton onClick={onSubmitGoogleSignIn} />
                </div>
            </main >
        </>
    )
}

const styles = {
    inputTextBox: {
        width: '60%',
        border: 'none',
        borderRadius: 10,
        boxShadow: '1px 4px 9px #F4AAB9',
        padding: 10
    },
}

export default Login