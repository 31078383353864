import { useEffect, useState } from "react"
import React from 'react'
import { COLORS } from "../../Constants/Colors"
import '../../index.css'
import { store } from "../../redux/store"
import { useNavigate } from "react-router-dom"


const buttons = []
for (let i = 0; i <= 10; i++) {
    buttons.push({ rating: i, selected: false })
}


export default function RatingSystem() {
    const [rating, setRating] = useState('')
    const navigate = useNavigate()
    useEffect(() => {
        if (!store.getState().user) {
            navigate('/login')
            navigate(0)
        }
    }, [])

    const onClickRatingButtons = (number) => {
        buttons.forEach((curr) => { if (curr !== number) { curr.selected = false } })
        setRating(number.rating)
        number.selected = !number.selected
    }
    return (
        <div className='rating-page'>
            <div className='page-header'>
                <h1>Rate your day.</h1>
                <h1>{rating}</h1>
                <div className='rating-buttons-container' >

                    {buttons.map((current) => {
                        return (
                            <button
                                style={{ backgroundColor: current.selected ? COLORS.background : 'dodgerblue' }}
                                onClick={() => onClickRatingButtons(current)}
                                className='rating-button'>
                                {current.rating}
                            </button>)
                    })}
                </div>
            </div>
        </div >
    )
}
