import React from 'react'

export default function About() {
    return (
        <div>
            <div className='page-header'>
                <h1> About Moss </h1>
            </div>
        </div>
    )
}
