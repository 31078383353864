import { combineReducers, createReducer } from "@reduxjs/toolkit";
import types from "../actions/actionTypes";

const initialUserState = ''

function userStateReducer(state = initialUserState, action) {

    switch (action.type) {
        case types.LOGIN:
            return {
                ...state,
                user: action.payload
            }
        case types.LOGOUT:
            return {
                ...state,
                user: null
            }
        default: return state
    }
}

export default userStateReducer